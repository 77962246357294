import { getAdropData, requestGetWithFullURL } from '@/api'

const ENDPOINT_REQUEST = 'request'
const defaultParams = {
  uid: '01JA95VS0SGVRJVNBEQBT2YRYE',
  pf: 'web',
  lcl: 'ko-KR'
}

export default {
  namespaced: true,
  state: {
    adropData: null,
    log: ''
  },

  actions: {
    // 광고 유닛 조회
    async getAdropUnitData ({ state }, payload) {

      // 에이드랍 데이터가 없는 경우만 API 호출
      if (state.adropData === null) {
        const resp = await getAdropData(ENDPOINT_REQUEST, 'get', 
          { 
          ...defaultParams,
          ...payload
        })
        if (resp?.data?.result) {
          state.adropData = resp?.data?.result
        }

        return resp
      } else {
        return {
          data: {
            result: state.adropData
          }
        }
      }
    },
    // 광고 트래커 호출
    async trackingAdrop ({ state }, fullurl) {
      console.log(state.log)
      const resp = await requestGetWithFullURL(fullurl)
      return resp
    }
  },
  mutations: {
    INIT_ADROP_DATA (state) {
      state.adropData = null
    }
  }
}